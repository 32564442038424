<script>
import BaseSelector from "@/components/BaseSelector";
export default {
  extends: BaseSelector,
  props: {
    title: {
      default: "Status",
      type: String,
    },
    itemText: {
      default: "label",
      type: String,
    },
    itemValue: {
      default: "value",
      type: String,
    },
  },
  computed: {
    localOptions() {
      return [
        {
          label: "Public",
          value: "public",
        },
        {
          label: "Draft",
          value: "draft",
        },
        {
          label: "Private",
          value: "private",
        },
        {
          label: "Onboarding",
          value: "onboarding",
        },
      ];
    },
  },
};
</script>
