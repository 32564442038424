<template>
  <nav class="flex mb-2">
    <ol class="flex items-center space-x-4">
      <li>
        <div>
          <a
            v-if="selectedList.length > 0"
            @click="
              (selectedList = []) &&
                router.push({ name: 'destinations-result' })
            "
            class="text-gray-400 hover:text-gray-500"
          >
            <HomeIcon class="flex-shrink-0 h-5 w-5" aria-hidden="true" />
            <span class="sr-only">Menu</span>
          </a>
        </div>
      </li>
      <template v-for="(item, index) in selectedList" :key="index">
        <li v-if="index > 0" class="flex items-center">
          <ChevronRightIcon
            class="flex-shrink-0 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <router-link
            :to="{
              name: 'destinations-result',
              query: { title: getDestination(index).title },
            }"
            v-slot="{ navigate }"
            custom
          >
            <a
              @click="handleSelectedItemClicked(index, navigate)"
              class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              >{{ getDestination(index).title }}</a
            >
          </router-link>
        </li>
      </template>
      <li v-if="route.params.title">
        {{ route.params.title }}
      </li>
    </ol>
  </nav>
  <div
    class="mb-2"
    v-for="(destination, index) in search
      ? result.children
      : activeItem.children"
    :key="`${index}-destination`"
  >
    <router-link
      :to="{ name: 'destinations-result', query: { title: destination.title } }"
      v-slot="{ href, navigate, isActive, route: itemRoute }"
      custom
    >
      <a
        :href="href"
        @click.prevent="handleDestinationClicked(navigate, destination)"
        :class="{
          'cursor-pointer': destination.children,
          'bg-gray-800 text-white':
            itemRoute.query?.title === route.query?.title,
          'bg-gray-200 hover:bg-gray-300 text-black': !(
            itemRoute.query?.title === route.query?.title
          ),
        }"
        class="
          px-4
          py-2
          rounded-md
          w-full
          text-left
          flex flex-row
          items-center
          justify-between
          text-sm
        "
      >
        <span>{{ destination.title }}</span>
        <ChevronRightIcon
          v-if="destination.children"
          class="h-5 w-5"
          :class="{ 'text-gray-700': !isActive, 'text-white': isActive }"
        />
      </a>
    </router-link>
  </div>
</template>

<script>
import { MENU_ITEMS, FLAT_MENU_ITEMS } from "@/assets/data/destinations";
import { ChevronRightIcon, HomeIcon } from "@heroicons/vue/solid";
import { computed, reactive, ref, watch } from "vue-demi";
import Fuse from "fuse.js";
import { useRouter, useRoute } from "vue-router";

export default {
  props: {
    search: {
      type: String,
      default: "",
    },
  },
  components: {
    ChevronRightIcon,
    HomeIcon,
  },
  setup(props) {
    const selectedList = ref([]);
    const result = reactive({ children: [] });

    const fuse = new Fuse(FLAT_MENU_ITEMS, { keys: ["title"], threshold: 0.3 });

    watch(
      () => props.search,
      (value) => {
        result.children = fuse
          .search(value)
          .map((item) => ({ title: item.item.title }));
      }
    );

    const activeItem = computed(() => {
      let currentDestination = { children: MENU_ITEMS };

      selectedList.value.forEach((item) => {
        currentDestination = currentDestination.children[item];
      });

      return currentDestination;
    });

    const handleDestinationClicked = (navigate, destination) => {
      if (destination.children) {
        let index = activeItem.value.children.indexOf(destination);
        selectedList.value = [...selectedList.value, index];
      }
      navigate();
    };

    const getDestination = (index) => {
      let currentDestination = { title: "Home", children: MENU_ITEMS };

      [...Array(index).keys()].forEach((key) => {
        currentDestination =
          currentDestination.children[selectedList.value[key]];
      });

      return currentDestination;
    };

    const handleSelectedItemClicked = (index, navigate) => {
      selectedList.value = selectedList.value.slice(0, index);
      navigate();
    };

    const getCurrentDestinationTitle = (index) => {
      return index;
    };

    const router = useRouter();
    const route = useRoute();

    return {
      route,
      selectedList,
      activeItem,
      handleDestinationClicked,
      getDestination,
      handleSelectedItemClicked,
      result,
      router,
      getCurrentDestinationTitle,
    };
  },
};
</script>
