import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  // uri: "http://localhost/graphql",
  uri: "https://next.lacuredata.com/graphql",
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      // Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI2IiwianRpIjoiZmNiNmQxNmIyMWU4MjliZDc1MjVjOGMzNDJjYWZkZjJmNzJlNjRiOWI0MmZkZDA2ZDAzMzBhMWI2M2QwMjZkNGFkYjhjZmZkZmI3MGI4MGQiLCJpYXQiOjE2NDQyNDczMDYuOTk0MzQ2LCJuYmYiOjE2NDQyNDczMDYuOTk0MzQ4LCJleHAiOjE2NzU3ODMzMDYuOTg0MTEsInN1YiI6IjEiLCJzY29wZXMiOltdfQ.n9mMK0SQJZDGotOotGBy630XmEe7la4msMciWJ0i7HXHrpptm2MeHfvc2e-5MJHbpwugnvhdCCqdmg0ImkzZC3uZQbTT6dc6eoC0IZT4zO4F7M-Fy1s3Qq_V5p7PboXj_eAS2xdPK7ZKKBSGiLUB1WxyZH31xwZMFTpPZ3gCK1mEdy-uk7B0vIwT30RAYqE6FSP0ECIB9rEYz-E6aEdnb9yA4Qkw7RSbRj1Pi5qQLRK4rNy7H-iAqVw-rSTXtCT7vYghWARK3RMZI-PO-HMUR-YMKr9B-HVqZNkBUliDU2UANeLNv99r7sBBBAoP32yoPtCDZh8xSx2V6IBdXa0lC4FLLDV6PCnx8I9a6BqckgmIdUsFNt27c0q8B6qhrpuMRL1Px9lg7oOAcnk-PzrgHYlu5QqIQA9wmGa8AhPGmHVIZCtPfxPrjoewcgZbFPZrVyiwQOIFvEddb3cFqH_mzIyE5g5IbYbSCT1EJN5OssGMdrUEy2y75-ez-a2dFrmada1GWpj1JWMppwlDYoCsjo8ekL4riGIIVpuO8qdv6dflJipCAoo0Y_m6kO8Gm-f2EE0h67mcpCYKK2Tw34QmryBhNqETb3wht8XFTJjWm0RbyEsGDLMjr9TJvPNP7BICK-j9rVVcwUpHCA12aWLSots_J-6Kz_su25XLEfj4u1U`,
      Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiOGQ1ZTkwOTIyNzQwMzE0MDVkNTJhZDY3OGM0NTE5NGQ3MmQzNjIxMmJmM2MwNDRiN2MwMmMyYjRmNGM5NTE5YmI3MTAwZjEzM2NiMzYyMGIiLCJpYXQiOjE2NjYxODc0NDAuNTc5OTUyLCJuYmYiOjE2NjYxODc0NDAuNTc5OTU1LCJleHAiOjE3NjA4ODE4MjQuMDkwMjg4LCJzdWIiOiIzIiwic2NvcGVzIjpbXX0.hQJZtielNdakGytyTBRoE95gfwIKw30f9AM13vc_TUSI1Fx-YZwKSxTnsp9ueisHQGkuZ-Ur2fAEvpjdxXp5Ei_rMR1ii3PXVJ4SwgAwA7kRb_oPGZc_dihdqYjmnOkH5hLVqL4OQMlXN1gpQM6mqeyBp_1_a7okjf0g8xR7e-XaHFgG-dwn0xrgpZefPoMn_faDr343sV9Lh1fPPTZkDH7qZIZtqTBljmirWfsYqNOPUZpPkLaq9ubKfMRVeA7P5aHx0jXhV85fPVY2wMrAeR6dtTXZ3q5sWrPXKonYLQXX56-l7TUc0722joMPigwBYPS-v5-F8YjdkOui7vIGT0gmcWKyl76fUzd6oeVjtWKY80w84b-jYUK7KTQxYEBfuXXJnW39l4Se7pjR-HcxSGdDEVEbcwWPeS34B80e7t_e40RDXquweXMSEs_kuz0wj0TctsvsQpsEgF-NbImjHnTZ9IvYSOtEfBAEt6Md6QTGdZYT3VMxbANYxjQfT8F8C3KV6k48xSEgHyrtVc7dOnIFZo3DsL1Ackcdr8RkVw_2yrdT3-GfXtBvuAtHd4QXnPQvFZ2tG12BYPWe7an34gnxW8QttUzFPQOAEW3n-GcNsQaFf3qXJ6S_Ap-6PKtzVpz2CVzdYfh8tvlhtKVQrK17bGAs7dPYLx32mPaUj00`,
    },
  };
});

// Cache implementation
const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        activityLogs: {
          read(existing) {
            return existing;
          },
          merge(existing, incoming) {
            let logs = Object.assign([], existing ? existing.data : []);

            incoming.data.forEach((villa, index) => {
              logs[index + incoming.paginatorInfo.firstItem - 1] = villa;
            });

            return {
              ...incoming,
              data: logs,
            };
          },
        },
        villas: {
          keyArgs: [
            "search",
            "country",
            "vendor",
            "calendar",
            "calendarStatus",
          ],
          read(existing, { args: { first, page } }) {
            return (
              existing && {
                ...existing,
                data: existing.data.slice(
                  first * (page - 1),
                  first * (page - 1) + first
                ),
              }
            );
          },
          merge(existing, incoming) {
            let items = Object.assign([], existing ? existing.data : []);

            incoming.data.forEach((item, index) => {
              items[index + incoming.paginatorInfo.firstItem - 1] = item;
            });

            return {
              ...incoming,
              data: items,
            };
          },
        },
      },
    },
  },
});

// Create the apollo client
const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});

export default apolloClient;
