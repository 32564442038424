<script>
import BaseSelector from "@/components/BaseSelector";
import { GET_VENDORS } from "@/apollo/queries/vendors";

export default {
  extends: BaseSelector,
  props: {
    title: {
      default: "Vendor",
      type: String,
    },
    itemText: {
      default: "name",
      type: String,
    },
  },
  apollo: {
    vendors: {
      query: GET_VENDORS,
      variables: {
        page: 1,
        perPage: 1000,
      },
    },
  },
  computed: {
    localOptions() {
      return this.vendors && this.vendors.data ? this.vendors.data : [];
    },
  },
};
</script>
