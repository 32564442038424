<template>
  <div v-if="result" class="bg-white overflow-hidden shadow sm:rounded-lg">
    <div class="px-4 py-5 sm:p-6">
      <div class="flow-root">
        <ul class="-mb-8">
          <template
            v-for="(log, index) in result.activityLogs.data"
            :key="log.id"
          >
            <li>
              <div class="relative pb-8">
                <span
                  v-if="index !== result.activityLogs.data.length - 1"
                  class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
                <div class="relative flex space-x-3">
                  <div>
                    <span
                      :class="color(log.properties.type)"
                      class="
                        h-8
                        w-8
                        rounded-full
                        flex
                        items-center
                        justify-center
                        ring-8 ring-white
                      "
                    >
                      <component
                        :is="icon(log.properties.type)"
                        class="h-5 w-5 text-white"
                        aria-hidden="true"
                      ></component>
                    </span>
                  </div>
                  <div
                    class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4"
                  >
                    <div>
                      <p class="text-sm text-gray-500">
                        {{ log.description }}
                      </p>
                    </div>
                    <div
                      class="text-right text-sm whitespace-nowrap text-gray-500"
                    >
                      <time :datetime="timeFromNow(log.created_at)">{{
                        timeFromNow(log.created_at)
                      }}</time>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>

  <div
    v-if="result?.activityLogs?.paginatorInfo?.hasMorePages"
    class="relative my-9"
  >
    <div class="absolute inset-0 flex items-center" aria-hidden="true">
      <div class="w-full border-t border-gray-300" />
    </div>
    <div class="relative flex justify-center">
      <button
        @click="loadMore"
        type="button"
        class="
          inline-flex
          items-center
          shadow-sm
          px-4
          py-1.5
          border border-gray-300
          text-sm
          leading-5
          font-medium
          rounded-full
          text-gray-500
          bg-white
          hover:bg-gray-50
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-indigo-500
        "
      >
        <span>Load More</span>
      </button>
    </div>
  </div>
</template>

<script>
import { useQuery } from "@vue/apollo-composable";
import { GET_LOGS } from "@/apollo/queries/calendarLogs";
import {
  CheckIcon,
  InformationCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/vue/solid";
import moment from "moment";
import { ref } from "vue-demi";

export default {
  components: {
    CheckIcon,
    InformationCircleIcon,
    ExclamationCircleIcon,
  },
  props: {
    calendarId: String,
  },
  setup(props) {
    let page = ref(1);

    const { result, fetchMore } = useQuery(
      GET_LOGS,
      {
        calendar: props.calendarId,
        page: page.value,
      },
      { fetchPolicy: "network-only" }
    );

    const timeFromNow = (datetime) => {
      return moment.utc(datetime).fromNow();
    };

    const icon = (type) => {
      switch (type) {
        case "danger":
          return "ExclamationCircleIcon";
        case "success":
          return "CheckIcon";
        case "info":
        default:
          return "InformationCircleIcon";
      }
    };

    const color = (type) => {
      switch (type) {
        case "danger":
          return "bg-red-400";
        case "success":
          return "bg-green-400";
        case "info":
        default:
          return "bg-blue-400";
      }
    };

    const loadMore = () => {
      page.value++;
      fetchMore({
        variables: {
          page: page.value,
        },
      });
    };

    return {
      result,
      icon,
      color,
      timeFromNow,
      loadMore,
    };
  },
};
</script>
