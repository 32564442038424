import gql from "graphql-tag";

export default gql`
  mutation upsertDestination(
    $id: ID
    $bounding_box: [Float]
    $is_disabled: Boolean
    $boundary: String
  ) {
    upsertDestination(
      id: $id
      bounding_box: $bounding_box
      is_disabled: $is_disabled
      boundary: $boundary
    ) {
      id
      is_disabled
      bounding_box
      boundary {
        __typename
        ... on DestinationPolygon {
          coordinates
        }
        ... on DestinationMultiPolygon {
          coordinates
        }
      }
    }
  }
`;
