<template>
  <div v-if="!succeeded" class="ml-2 py-1 px-2 rounded-lg bg-gray-200">
    <button v-if="!failed && !succeeded" @click.prevent="handleRetry(id)">
      <span v-text="getText"></span>
    </button>

    <button v-else-if="!succeeded">Calendar problem</button>
  </div>
</template>

<script>
import { useMutation } from "@vue/apollo-composable";
import SYNC_CALENDAR from "@/apollo/mutations/synchronizeCalendarByVilla";
import { computed, ref } from "vue";
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup() {
    const failed = ref(false);
    const succeeded = ref(false);

    const { mutate, loading, onDone, onError } = useMutation(SYNC_CALENDAR, {
      variables: { id: null },
    });

    const handleRetry = (id) => {
      mutate({ id });
    };

    const getText = computed(() => {
      return loading.value ? "Loading..." : "Retry";
    });

    onDone(() => {
      succeeded.value = true;
    });

    onError(() => {
      failed.value = true;
    });

    return {
      succeeded,
      failed,
      getText,
      handleRetry,
    };
  },
};
</script>
