export const MENU_ITEMS = [
  {
    title: "Caribbean",
    children: [
      {
        title: "British Virgin Islands",
      },
      {
        title: "Bermuda",
      },
      {
        title: "Anguilla",
      },
      {
        title: "Antigua",
        children: [
          {
            title: "Jumby Bay",
          },
        ],
      },
      {
        title: "Bahamas",
      },
      {
        title: "Barbados",
        children: [
          {
            title: "Holetown",
          },
          {
            title: "Christ Church",
          },
          {
            title: "St. James Parish",
          },
          {
            title: "Sandy Lane",
          },
          {
            title: "St. Peter Parish",
          },
        ],
      },
      {
        title: "Calivigny & Grenada",
      },
      {
        title: "Canouan-St.Vincent & the Grenadines",
      },
      {
        title: "Dominican Republic",
        children: [
          {
            title: "Punta Cana",
          },
          {
            title: "Casa de Campo",
          },
        ],
      },
      {
        title: "Grand Cayman",
      },
      {
        title: "Jamaica",
        children: [
          {
            title: "Whitehouse-Treasure Beach",
          },
          {
            title: "Discovery Bay",
          },
          {
            title: "Montego Bay",
          },
          {
            title: "Ocho Rios",
          },
        ],
      },
      {
        title: "Mustique",
      },
      {
        title: "Necker Island",
      },
      {
        title: "St. Barths",
        children: [
          {
            title: "St. Jean",
          },
          {
            title: "Pointe Milou",
          },
          {
            title: "Petit Cul de Sac",
          },
          {
            title: "Lurin",
          },
          {
            title: "Flamands",
          },
        ],
      },
      {
        title: "St. John",
      },
      {
        title: "St. Lucia",
        children: [
          {
            title: "Cap Estate",
          },
          {
            title: "Rodney Bay",
          },
        ],
      },
      {
        title: "St. Martin",
        children: [
          {
            title: "Simpson Bay",
          },
        ],
      },
      {
        title: "St. Thomas",
      },
      {
        title: "Tortola",
      },
      {
        title: "Turks and Caicos",
      },
      {
        title: "Virgin Gorda",
      },
    ],
  },
  {
    title: "Mexico",
    children: [
      {
        title: "Oaxaca",
      },
      {
        title: "Cabo San Lucas",
      },
      {
        title: "Mayan Riviera",
      },
      {
        title: "Puerto Vallarta",
      },
      {
        title: "Punta Mita",
      },
    ],
  },
  {
    title: "Central America",
    children: [
      {
        title: "Costa Rica",
        children: [
          {
            title: "Santa Teresa",
          },
          {
            title: "Manuel Antonio",
          },
          {
            title: "Guanacaste",
          },
        ],
      },
    ],
  },
  {
    title: "South America",
    children: [
      {
        title: "Argentina",
      },
      {
        title: "Brazil",
        children: [
          {
            title: "Rio de Janeiro",
          },
        ],
      },
    ],
  },
  {
    title: "United States of America",
    children: [
      {
        title: "Massachusetts",
      },
      {
        title: "Montana",
      },
      {
        title: "Idaho",
      },
      {
        title: "New Mexico",
      },
      {
        title: "Wisconsin",
      },
      {
        title: "Texas",
      },
      {
        title: "Arizona",
      },
      {
        title: "California",
        children: [
          {
            title: "Santa Barbara",
          },
          {
            title: "Coachella Valley",
          },
          {
            title: "Lake Tahoe",
          },
          {
            title: "Los Angeles",
          },
        ],
      },
      {
        title: "Colorado",
        children: [
          {
            title: "Telluride",
          },
          {
            title: "Breckenridge",
          },
          {
            title: "Aspen",
          },
          {
            title: "Beaver Creek",
          },
          {
            title: "Vail",
          },
        ],
      },
      {
        title: "Florida",
        children: [
          {
            title: "Fort Lauderdale",
          },
          {
            title: "Orlando",
          },
          {
            title: "Miami",
          },
        ],
      },
      {
        title: "Hawaii",
        children: [
          {
            title: "Big Island",
          },
          {
            title: "Oahu",
          },
          {
            title: "Kauai",
          },
          {
            title: "Maui",
          },
        ],
      },
      {
        title: "New York",
        children: [
          {
            title: "Hamptons",
          },
          {
            title: "New York City",
          },
        ],
      },
      {
        title: "Utah",
      },
    ],
  },
  {
    title: "Canada",
    children: [
      {
        title: "Muskoka",
      },
      {
        title: "Whistler",
      },
    ],
  },
  {
    title: "Europe",
    children: [
      {
        title: "Croatia",
      },
      {
        title: "England",
      },
      {
        title: "France",
        children: [
          {
            title: "South West",
          },
          {
            title: "Burgundy",
          },
          {
            title: "Var",
          },
          {
            title: "Cote d'Azur",
            children: [
              {
                title: "Cassis",
              },
              {
                title: "Cap D' Antibes",
              },
              {
                title: "St. Tropez",
              },
              {
                title: "St. Jean Cap Ferrat",
              },
              {
                title: "Cannes",
              },
              {
                title: "Cap Martin",
              },
              {
                title: "Nice",
              },
            ],
          },
          {
            title: "Paris",
          },
          {
            title: "Alpes",
            children: [
              {
                title: "Chamonix",
              },
              {
                title: "Courchevel",
              },
            ],
          },
          {
            title: "Corsica",
          },
          {
            title: "Languedoc",
          },
          {
            title: "Loire Valley",
          },
          {
            title: "Normandy",
          },
          {
            title: "Provence",
            children: [
              {
                title: "Les Alpilles",
              },
              {
                title: "St. Rémy de Provence",
              },
              {
                title: "Avignon",
              },
              {
                title: "Aix en Provence",
              },
              {
                title: "Luberon",
              },
            ],
          },
        ],
      },
      {
        title: "Greece",
        children: [
          {
            title: "Crete",
          },
          {
            title: "Island of Rhodes",
          },
          {
            title: "Mykonos",
          },
          {
            title: "Paros & Antiparos",
          },
          {
            title: "Santorini",
          },
        ],
      },
      {
        title: "Italy",
        children: [
          {
            title: "Amalfi Coast",
            children: [
              {
                title: "Maiori",
              },
              {
                title: "Positano",
              },
              {
                title: "Praiano",
              },
              {
                title: "Salerno",
              },
              {
                title: "Sorrento",
              },
              {
                title: "Capri",
              },
            ],
          },
          {
            title: "Lake Como",
          },
          {
            title: "Rome",
          },
          {
            title: "Puglia",
          },
          {
            title: "Sardinia",
          },
          {
            title: "Sicily",
          },
          {
            title: "Tuscany",
            children: [
              {
                title: "Chianti",
              },
              {
                title: "Florence",
              },
              {
                title: "Siena",
              },
            ],
          },
          {
            title: "Umbria",
          },
          {
            title: "Venice",
          },
        ],
      },
      {
        title: "Portugal",
      },
      {
        title: "Spain",
        children: [
          {
            title: "Andalusia",
          },
          {
            title: "Majorca",
          },
          {
            title: "Barcelona",
          },
          {
            title: "Ibiza",
          },
          {
            title: "Marbella",
          },
        ],
      },
      {
        title: "Switzerland",
        children: [
          {
            title: "Klosters",
          },
          {
            title: "Verbier",
          },
          {
            title: "Zermatt",
          },
        ],
      },
    ],
  },
  {
    title: "New Zealand",
  },
  {
    title: "Asia",
    children: [
      {
        title: "Philippines",
      },
      {
        title: "Arabian Peninsula",
      },
      {
        title: "Indian Ocean",
      },
      {
        title: "Indonesia",
      },
      {
        title: "Bora Bora",
      },
      {
        title: "Thailand",
      },
    ],
  },
  {
    title: "Africa",
    children: [
      {
        title: "Morocco",
      },
      {
        title: "South Africa",
        children: [
          {
            title: "Western Cape",
          },
        ],
      },
    ],
  },
];

function exploreChildren(destination, destinations) {
  if (destination.children) {
    destination.children.forEach((child) => {
      destinations = [...destinations, child];
      destinations = exploreChildren(child, destinations);
    });
  }
  return destinations;
}

let destinations = [];
let currentDestination = { children: MENU_ITEMS };

export const FLAT_MENU_ITEMS = exploreChildren(
  currentDestination,
  destinations
);
