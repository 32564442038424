import gql from "graphql-tag";

export default gql`
  mutation synchronizeCalendarByVilla($id: ID!) {
    synchronizeCalendarByVilla(id: $id) {
      id
      title
      code
      images
      status
      calendar_type
      calendar {
        __typename
        ... on ICalCalendar {
          id
          source
          failed_at
          scanned_at
        }
        ... on LuxuryCalendar {
          id
        }
      }
      country {
        name
      }
      vendor {
        name
      }
      reservations {
        start
        end
      }
      blackouts {
        start
        end
      }
    }
  }
`;
