<template>
  <div>
    <div v-if="result?.searchBlacklists">
      <div
        v-for="blacklist in result.searchBlacklists"
        :key="blacklist.id"
        class="
          px-4
          py-2
          rounded-md
          w-full
          text-left
          flex flex-row
          items-center
          justify-between
          text-sm
          bg-gray-200
          hover:bg-gray-300
          text-black
          cursor-pointer
        "
      >
        {{ blacklist.title }}
      </div>
    </div>
  </div>
</template>

<script>
import { GET_BLACKLISTS } from "@/apollo/queries/search-blacklists";
import { useQuery } from "@vue/apollo-composable";

export default {
  setup() {
    const { result, loading } = useQuery(GET_BLACKLISTS);

    return {
      result,
      loading,
    };
  },
};
</script>
