<template>
  <div
    class="
      bg-white
      px-4
      py-3
      flex
      items-center
      justify-between
      border-t border-gray-200
      sm:px-6
    "
  >
    <div class="flex-1 flex justify-between" :class="{ 'sm:hidden': !mini }">
      <a
        @click="currentPage > 1 && --currentPage"
        :class="{ 'cursor-pointer': currentPage > 1 }"
        class="
          relative
          inline-flex
          items-center
          px-4
          py-2
          border border-gray-300
          text-sm
          font-medium
          rounded-md
          text-gray-700
          bg-white
          hover:bg-gray-50
        "
      >
        Previous
      </a>
      <a
        @click="currentPage < lastPage && ++currentPage"
        :class="{ 'cursor-pointer': currentPage < lastPage }"
        class="
          ml-3
          relative
          inline-flex
          items-center
          px-4
          py-2
          border border-gray-300
          text-sm
          font-medium
          rounded-md
          text-gray-700
          bg-white
          hover:bg-gray-50
        "
      >
        Next
      </a>
    </div>
    <div
      :class="
        mini
          ? 'hidden'
          : 'hidden sm:flex-1 sm:flex sm:items-center sm:justify-between'
      "
    >
      <div>
        <p class="text-sm text-gray-700">
          Showing
          {{ " " }}
          <span class="font-medium" v-text="low"></span>
          {{ " " }}
          to
          {{ " " }}
          <span class="font-medium" v-text="high"></span>
          {{ " " }}
          of
          {{ " " }}
          <span class="font-medium" v-text="total"></span>
          {{ " " }}
          results
        </p>
      </div>
      <div>
        <nav
          class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          <a
            @click="currentPage > 1 && --currentPage"
            :class="{ 'cursor-pointer': currentPage > 1 }"
            class="
              select-none
              relative
              inline-flex
              items-center
              px-2
              py-2
              rounded-l-md
              border border-gray-300
              bg-white
              text-sm
              font-medium
              text-gray-500
              hover:bg-gray-50
            "
          >
            <span class="sr-only">Previous</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </a>

          <a
            @click="currentPage = 1"
            :class="
              currentPage === 1
                ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
                : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
            "
            class="
              select-none
              cursor-pointer
              relative
              inline-flex
              items-center
              px-4
              py-2
              border
              text-sm
              font-medium
            "
          >
            1
          </a>
          <span
            v-if="currentPage > size + 2"
            class="
              select-none
              relative
              inline-flex
              items-center
              px-4
              py-2
              border border-gray-300
              bg-white
              text-sm
              font-medium
              text-gray-700
            "
          >
            ...
          </span>
          <template v-for="(page, pageIndex) in pageGroups" :key="pageIndex">
            <a
              @click="currentPage = page"
              :class="
                currentPage === page
                  ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
                  : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
              "
              class="
                select-none
                cursor-pointer
                relative
                inline-flex
                items-center
                px-4
                py-2
                border
                text-sm
                font-medium
              "
            >
              {{ page }}
            </a>
          </template>
          <span
            v-if="currentPage < lastPage - size - 1"
            class="
              select-none
              relative
              inline-flex
              items-center
              px-4
              py-2
              border border-gray-300
              bg-white
              text-sm
              font-medium
              text-gray-700
            "
          >
            ...
          </span>
          <a
            v-if="lastPage && lastPage !== 1"
            @click="currentPage = lastPage"
            :class="
              currentPage === lastPage
                ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
                : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
            "
            class="
              select-none
              cursor-pointer
              relative
              inline-flex
              items-center
              px-4
              py-2
              border
              text-sm
              font-medium
            "
          >
            {{ lastPage }}
          </a>
          <a
            @click="currentPage < lastPage && ++currentPage"
            :class="{ 'cursor-pointer': currentPage < lastPage }"
            class="
              relative
              inline-flex
              items-center
              px-2
              py-2
              rounded-r-md
              border border-gray-300
              bg-white
              text-sm
              font-medium
              text-gray-500
              hover:bg-gray-50
            "
          >
            <span class="sr-only">Next</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/solid";
import { computed } from "vue-demi";

export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  props: {
    mini: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    firstItem: {
      type: Number,
      default: 0,
    },
    lastItem: {
      type: Number,
      default: 9,
    },
    lastPage: {
      type: Number,
      default: 1,
    },
    size: {
      type: Number,
      default: 2,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  emits: ["update:page"],
  setup(props, context) {
    const currentPage = computed({
      get() {
        return props.page;
      },
      set(val) {
        context.emit("update:page", val);
      },
    });

    const high = computed(() => props.lastItem);
    const low = computed(() => props.firstItem);

    const pages = computed(() => {
      return ~~(props.total / props.perPage);
    });

    const pageGroups = computed(() => {
      let first = currentPage.value - props.size;
      return [...Array(props.size * 2 + 1).keys()]
        .map((key) => {
          return first + key;
        })
        .filter((page) => page > 1 && page < props.lastPage);
    });

    return {
      currentPage,
      pages,
      high,
      low,
      pageGroups,
    };
  },
};
</script>
