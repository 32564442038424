<template>
  <div>
    <div class="flex-grow py-2 md:py-0 bg-gray-50 mb-4 rounded-lg shadow-md">
      <div class="relative flex items-center">
        <SearchIcon class="h-5 w-5 absolute ml-2 text-gray-500" />
        <input
          v-on:keyup.enter="handleEnterPressed"
          v-model="search"
          type="text"
          name="search"
          id="search"
          class="
            rounded-md
            pl-10
            shadow-none
            focus:ring-gray-400 focus:border-gray-400
            block
            w-full
            text-md
            border-none
            bg-transparent
          "
          placeholder="Search"
        />
      </div>
    </div>

    <router-view></router-view>

    <destinations-pinned :search="search" />
    <destinations-misc :search="search" />
  </div>
</template>

<script>
import DestinationsPinned from "@/components/destinations/DestinationsPinned";
import DestinationsMisc from "@/components/destinations/DestinationsMisc";
import { SearchIcon } from "@heroicons/vue/solid";
import { useRouter } from "vue-router";
import { ref } from "vue-demi";

export default {
  components: {
    DestinationsPinned,
    SearchIcon,
    DestinationsMisc,
  },
  setup() {
    const router = useRouter();

    const search = ref("");

    const handleEnterPressed = () => {
      router.push({
        name: "destinations-result",
        query: { title: search.value },
      });
    };

    return {
      handleEnterPressed,
      search,
    };
  },
};
</script>
