<template>
  <div class="max-w-7xl mx-auto py-6 px-6 grid grid-cols-2 gap-4 mt-4 bg-white">
    <div class="mt-14">
      <router-view />
    </div>
    <div>
      <div>
        <div class="mb-4">
          <div class="border-b border-gray-200">
            <nav class="-mb-px flex justify-end space-x-8" aria-label="Tabs">
              <router-link
                v-for="tab in tabs"
                :key="tab.name"
                :to="{ name: tab.path }"
                v-slot="{ navigate, href, isExactActive }"
                custom
              >
                <a
                  :href="href"
                  @click="navigate"
                  :class="[
                    isExactActive
                      ? 'border-gray-800 text-gray-800'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                    'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm',
                  ]"
                  :aria-current="isExactActive ? 'page' : undefined"
                >
                  {{ tab.name }}
                </a>
              </router-link>
            </nav>
          </div>
        </div>
      </div>
      <div style="min-height: 598px">
        <l-map
          v-model:bounds="bounds"
          :options="{ beforeMapMount, zoomSnap: 0, zoomDelta: 0.5 }"
          zoomAnimation
          useGlobalLeaflet
          ref="map"
          inertia
          style="height: 598px"
          @ready="onMapReady"
        >
          <l-tile-layer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy; <a target='_blank' href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
          ></l-tile-layer>

          <destination
            v-if="route.query.title"
            :map="map"
            v-model:bounds="bounds"
          ></destination>
        </l-map>
      </div>
    </div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer } from "@vue-leaflet/vue-leaflet";
import { useRoute } from "vue-router";
import { ref } from "vue-demi";
import Destination from "@/components/destinations/Destination";

export default {
  components: {
    LMap,
    LTileLayer,
    Destination,
  },
  setup() {
    const route = useRoute();

    const map = ref(null);

    const bounds = ref(null);

    const beforeMapMount = async () => {
      await import("@geoman-io/leaflet-geoman-free");
      await import("@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css");
    };

    const onMapReady = (map) => {
      map.pm.setPathOptions({
        fillOpacity: 0.4,
        fillColor: "#000",
        weight: 1,
        color: "#000",
      });
    };

    const tabs = [
      { name: "Destinations", path: "destinations-result", current: false },
      { name: "Blacklisted", path: "destination-blacklists", current: false },
    ];

    return {
      tabs,
      onMapReady,
      map,
      beforeMapMount,
      route,
      bounds,
    };
  },
};
</script>

<style>
.leaflet-pm-toolbar .leaflet-pm-icon-save {
  background-image: url("../assets/icons/Save.svg");
}
</style>
