<template>
  <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
    <villas-filter
      v-model:status="status"
      v-model:search="search"
      v-model:vendor="vendor"
      v-model:country="country"
      v-model:calendar="calendar"
      v-model:calendarStatus="calendarStatus"
    ></villas-filter>
    <villas-table
      :status="status"
      :search="search"
      :vendor="vendor"
      :country="country"
      :calendar="calendar"
      :calendarStatus="calendarStatus"
      class="mt-12"
    ></villas-table>
  </div>
</template>

<script>
import VillasTable from "@/components/villas/VillasTable";
import VillasFilter from "@/components/villas/VillasFilter";
import { useRoute, useRouter } from "vue-router";
import { computed } from "vue-demi";

export default {
  components: {
    VillasTable,
    VillasFilter,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const search = computed({
      get() {
        return route.query.search ?? "";
      },
      set(val) {
        router.push({ query: { ...route.query, search: val } });
      },
    });

    const country = computed({
      get() {
        return route.query.country ?? null;
      },
      set(val) {
        router.push({ query: { ...route.query, country: val } });
      },
    });

    const vendor = computed({
      get() {
        return route.query.vendor ?? null;
      },
      set(val) {
        router.push({ query: { ...route.query, vendor: val } });
      },
    });

    const status = computed({
      get() {
        return route.query.status ?? null;
      },
      set(val) {
        router.push({ query: { ...route.query, status: val } });
      },
    });

    const calendar = computed({
      get() {
        return route.query.calendar
          ? Array.isArray(route.query.calendar)
            ? [...route.query.calendar]
            : [route.query.calendar]
          : ["no-calendar", "i-calendar", "api"];
      },
      set(val) {
        router.push({
          query: { ...route.query, calendar: val },
        });
      },
    });

    const calendarStatus = computed({
      get() {
        return route.query.calendarStatus
          ? Array.isArray(route.query.calendarStatus)
            ? [...route.query.calendarStatus]
            : [route.query.calendarStatus]
          : ["failing", "succeeding"];
      },
      set(val) {
        router.push({ query: { ...route.query, calendarStatus: val } });
      },
    });

    return {
      status,
      calendarStatus,
      calendar,
      vendor,
      country,
      search,
    };
  },
};
</script>
