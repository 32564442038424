import gql from "graphql-tag";

export const GET_BLACKLISTS = gql`
  query searchBlacklists {
    searchBlacklists(orderBy: { column: TITLE, order: ASC }) {
      id
      title
    }
  }
`;

export const CREATE_BLACKLIST = gql`
  mutation createBlacklist($title: String!) {
    createBlacklist(input: { title: $title }) {
      id
      title
    }
  }
`;
