<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <div class="relative" style="min-height: 150px">
            <table class="min-w-full divide-y divide-gray-200 text-sm">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    ID
                  </th>
                  <th
                    scope="col"
                    class="
                      w-96
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="
                      w-96
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Vendor
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Country
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Calendar
                  </th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody v-if="result" class="bg-white divide-y divide-gray-200">
                <template
                  v-for="(villa, villaIndex) in result.villas.data"
                  :key="villaIndex"
                >
                  <tr>
                    <td class="px-6 py-4 whitespace-nowrap">
                      {{ villa.code }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      {{ villa.title }}

                      <span
                        v-if="villa.is_quicklisting"
                        class="
                          inline-flex
                          items-center
                          rounded-full
                          bg-gray-100
                          px-2.5
                          py-0.5
                          text-xs
                          font-medium
                          text-gray-800
                        "
                        >QL</span
                      >
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <span
                        class="
                          inline-flex
                          items-center
                          px-2.5
                          py-0.5
                          rounded-full
                          text-xs
                          font-medium
                          capitalize
                        "
                        :class="{
                          'bg-yellow-100 text-yellow-800':
                            villa.status === 'draft',
                          'bg-green-100 text-green-800':
                            villa.status === 'public',
                        }"
                      >
                        {{ villa.status }}
                      </span>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      {{ villa.vendor?.name }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      {{ villa.country?.name }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap flex items-center">
                      <div v-if="!villa.calendar">No Calendar</div>
                      <div
                        v-if="
                          villa.calendar &&
                          villa.calendar_type === 'LuxuryRentals'
                        "
                      >
                        API
                      </div>
                      <template
                        v-if="villa.calendar && villa.calendar_type === 'ICal'"
                      >
                        <div
                          class="
                            flex
                            items-center
                            text-xs
                            py-1
                            px-2
                            rounded-lg
                            ml-1
                            font-medium
                          "
                          :class="
                            villa.calendar.failed_at
                              ? 'bg-red-100 text-red-800'
                              : 'bg-green-100 text-green-800'
                          "
                        >
                          <component
                            class="h-5 w-5 mr-1"
                            :class="
                              villa.calendar.failed_at
                                ? 'text-red-400'
                                : 'text-green-400'
                            "
                            :is="
                              villa.calendar.failed_at
                                ? 'ExclamationCircleIcon'
                                : 'CheckCircleIcon'
                            "
                          >
                          </component>
                          <span
                            v-text="
                              fromNow(
                                villa.calendar.failed_at ??
                                  villa.calendar.scanned_at
                              )
                            "
                          >
                          </span>
                        </div>
                        <CalendarRetryButton :id="villa.id" />
                      </template>
                    </td>
                    <td
                      class="
                        px-6
                        py-4
                        whitespace-nowrap
                        text-right text-sm
                        font-medium
                        text-indigo-600
                        hover:text-indigo-900
                      "
                    >
                      <router-link
                        v-if="villa?.calendar?.__typename === 'ICalCalendar'"
                        :to="{ name: 'villaLogs', params: { id: villa.id } }"
                        custom
                        v-slot="{ href, navigate }"
                      >
                        <a
                          class="font-medium ml-2"
                          :href="href"
                          @click="navigate"
                          >Logs</a
                        >
                      </router-link>

                      <a
                        class="font-medium ml-2"
                        v-if="villa?.calendar?.__typename === 'ICalCalendar'"
                        :href="villa.calendar.source"
                      >
                        Calendar
                      </a>

                      <a
                        :href="`https://lacurevillas.com?villa-id=${villa.code}`"
                        target="_new"
                        class="font-medium ml-2"
                        >Page</a
                      >
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <ui-pagination
            v-model:page="page"
            class="pt-6"
            :total="result?.villas?.paginatorInfo?.total"
            :last-page="result?.villas?.paginatorInfo?.lastPage"
            :last-item="result?.villas?.paginatorInfo?.lastItem"
            :first-item="result?.villas?.paginatorInfo?.firstItem"
          ></ui-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watch } from "vue-demi";
import { useRoute, useRouter } from "vue-router";
import { useQuery } from "@vue/apollo-composable";
import { GET_VILLAS } from "@/apollo/queries/villas";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/vue/solid";
import UiPagination from "@/components/UiPagination";
import UiLoader from "@/components/UiLoader";
import moment from "moment";
import CalendarRetryButton from "./CalendarRetryButton.vue";

function buildQuery({
  search,
  country,
  vendor,
  calendar,
  calendarStatus,
  status,
}) {
  let where = {};
  let and = [];
  let hasCalendar;

  if (calendarStatus) {
    let or = [];

    if (calendarStatus.includes("failing")) {
      or.push({
        column: "FAILED_AT",
        operator: "IS_NOT_NULL",
      });
    }

    if (calendarStatus.includes("succeeding")) {
      or.push({
        column: "FAILED_AT",
        operator: "IS_NULL",
      });
    }

    if (or.length > 0) {
      hasCalendar = {
        OR: or,
      };
    }
  }

  if (status) {
    and = [
      ...and,
      {
        column: "STATUS",
        operator: "EQ",
        value: status,
      },
    ];
    // and.push({});
  }

  if (calendar) {
    let or = [];
    if (calendar.includes("no-calendar")) {
      or.push({
        column: "CALENDAR_TYPE",
        operator: "IS_NULL",
      });
    }

    if (calendar.includes("i-calendar")) {
      or.push({
        column: "CALENDAR_TYPE",
        operator: "EQ",
        value: "ICal",
      });
    }

    if (calendar.includes("api")) {
      or.push({
        column: "CALENDAR_TYPE",
        operator: "EQ",
        value: "LuxuryRentals",
      });
    }

    if (or.length > 0) {
      and.push({
        OR: or,
      });
    }
  }

  if (search) {
    and = [
      ...and,
      {
        OR: [
          { column: "TITLE", operator: "LIKE", value: search },
          { column: "CODE", operator: "EQ", value: search },
          { column: "ID", operator: "EQ", value: search },
          {
            HAS: {
              relation: "vendor",
              condition: { column: "NAME", operator: "LIKE", value: search },
            },
          },
        ],
      },
    ];
  }

  if (and.length) {
    where.AND = and;
  }

  let query = {
    hasCalendar: hasCalendar ?? null,
    hasVendor: vendor ? { column: "ID", operator: "EQ", value: vendor } : null,
    hasCountry: country
      ? { column: "ID", operator: "EQ", value: country }
      : null,
  };

  if (Object.keys(where).length) {
    query.where = where;
  }

  return query;
}

export default {
  components: {
    UiPagination,
    UiLoader,
    CheckCircleIcon,
    ExclamationCircleIcon,
    CalendarRetryButton,
  },
  props: {
    status: String,
    search: String,
    vendor: String,
    country: String,
    calendar: Array,
    calendarStatus: Array,
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();

    const fromNow = (datetime) => {
      return moment.utc(datetime).fromNow();
    };

    let page = computed({
      get() {
        return route.query.page ? parseInt(route.query.page) : 1;
      },
      set(val) {
        router.push({ query: { ...route.query, page: val } });
      },
    });

    const { result, loading, fetchMore, refetch } = useQuery(GET_VILLAS, {
      page: page.value,
      ...buildQuery({
        status: props.status,
        search: props.search,
        country: props.country,
        vendor: props.vendor,
        calendar: props.calendar,
        calendarStatus: props.calendarStatus,
      }),
    });

    watch(page, () => {
      fetchMore({
        variables: {
          page: page.value,
          ...buildQuery({
            status: props.status,
            search: props.search,
            country: props.country,
            vendor: props.vendor,
            calendar: props.calendar,
            calendarStatus: props.calendarStatus,
          }),
        },
      });
    });

    watch(
      [
        () => props.status,
        () => props.search,
        () => props.country,
        () => props.vendor,
        () => props.calendar,
        () => props.calendarStatus,
      ],
      ([status, search, country, vendor, calendar, calendarStatus]) => {
        refetch({
          page: page.value,
          ...buildQuery({
            status,
            search,
            country,
            vendor,
            calendar,
            calendarStatus,
          }),
        });
      },
      { immediate: false }
    );

    return {
      fromNow,
      router,
      page,
      result,
      loading,
    };
  },
};
</script>

<style scoped>
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
