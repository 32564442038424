<template>
  <template v-if="result">
    <l-control position="bottomright">
      <button
        class="
          inline-flex
          items-center
          px-2.5
          py-1.5
          border border-gray-300
          shadow-sm
          text-xs
          font-medium
          rounded
          text-gray-700
          bg-white
          hover:bg-gray-50
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-indigo-500
          mr-2
        "
        @click="updateBoundingBox"
      >
        Save bounding box
      </button>
      <button @click="hello"></button>
    </l-control>

    <l-control position="topright">
      <template v-if="result?.newDestination?.boundary">
        <button
          v-text="
            result.newDestination.is_disabled
              ? 'Enable boundary'
              : 'Disable Boundary'
          "
          @click="handleToggleBoundary"
          class="
            inline-flex
            items-center
            px-2.5
            py-1.5
            border border-gray-300
            shadow-sm
            text-xs
            font-medium
            rounded
            text-gray-700
            bg-white
            hover:bg-gray-50
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            mr-2
          "
        ></button>
        <button
          @click="handleScaleUp"
          class="
            inline-flex
            items-center
            px-2.5
            py-1.5
            border border-gray-300
            shadow-sm
            text-xs
            font-medium
            rounded
            text-gray-700
            bg-white
            hover:bg-gray-50
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            mr-2
          "
        >
          Grow
        </button>
        <button
          @click="handleSimplify"
          class="
            inline-flex
            items-center
            px-2.5
            py-1.5
            border border-gray-300
            shadow-sm
            text-xs
            font-medium
            rounded
            text-gray-700
            bg-white
            hover:bg-gray-50
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            mr-2
          "
        >
          Simplify
        </button>
        <button
          v-text="isEditing ? 'Stop editing' : 'Edit boundary'"
          @click="handleEdit"
          class="
            inline-flex
            items-center
            px-2.5
            py-1.5
            border border-gray-300
            shadow-sm
            text-xs
            font-medium
            rounded
            text-gray-700
            bg-white
            hover:bg-gray-50
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            mr-2
          "
        ></button>

        <button
          @click="handleSaveBoundary"
          class="
            inline-flex
            items-center
            px-2.5
            py-1.5
            border border-gray-300
            shadow-sm
            text-xs
            font-medium
            rounded
            text-gray-700
            bg-white
            hover:bg-gray-50
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
          "
        >
          Save
        </button>
      </template>
      <template v-else>
        <div class="flex space-x-2">
          <button
            @click="handleAddBoundary"
            class="
              inline-flex
              items-center
              px-2.5
              py-1.5
              border border-gray-300
              shadow-sm
              text-xs
              font-medium
              rounded
              text-gray-700
              bg-white
              hover:bg-gray-50
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
            "
          >
            Toggle Editor
          </button>
          <button
            @click="handleSaveBoundary"
            class="
              inline-flex
              items-center
              px-2.5
              py-1.5
              border border-gray-300
              shadow-sm
              text-xs
              font-medium
              rounded
              text-gray-700
              bg-white
              hover:bg-gray-50
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
            "
          >
            Save
          </button>
        </div>
      </template>
    </l-control>

    <template v-if="coordinates">
      <l-geo-json
        :geojson="coordinates"
        ref="boundary"
        :options="{
          weight: 1,
          color: '#000',
          fillOpacity: 0.4,
          fillColor: '#000',
          useGlobalLeaflet: true,
        }"
        fill
      ></l-geo-json>
    </template>
  </template>
  <l-control v-else position="topleft"> fetching </l-control>
</template>

<script>
import { useRoute } from "vue-router";
import { computed, ref, watch, watchEffect } from "vue-demi";
import { LGeoJson, LControl } from "@vue-leaflet/vue-leaflet";
import { latLng, latLngBounds } from "leaflet";
import { GET_DESTINATION } from "@/apollo/queries/destinations";
import { useMutation, useQuery } from "@vue/apollo-composable";
import UPDATE_DESTINATION from "@/apollo/mutations/updateDestination";
import simplify from "@turf/simplify";
import buffer from "@turf/buffer";
import union from "@turf/union";
import precision from "geojson-precision";
import cleanCoords from "@turf/clean-coords";

export default {
  components: {
    LGeoJson,
    LControl,
  },
  props: {
    zoom: Number,
    center: Object,
    map: Object,
    bounds: Object,
    is_loading: Boolean,
  },
  emits: ["update:bounds", "update:is_loading"],
  setup(props, { emit }) {
    const route = useRoute();
    const title = computed(() => route.query.title);
    const coordinates = ref(null);

    const { result, refetch, loading, onResult } = useQuery(GET_DESTINATION, {
      title: title,
    });

    onResult(({ data }) => {
      isEditing.value = false;
      props.map.leafletObject.pm.removeControls();
      props.map.leafletObject.pm.disableGlobalEditMode();

      props.map.leafletObject.pm.getGeomanDrawLayers().forEach((layer) => {
        props.map.leafletObject.removeLayer(layer);
      });

      if (data?.newDestination) {
        let bounds = latLngBounds(
          latLng(
            data.newDestination.bounding_box[1],
            data.newDestination.bounding_box[3]
          ),
          latLng(
            data.newDestination.bounding_box[0],
            data.newDestination.bounding_box[2]
          )
        );

        emit("update:bounds", bounds);

        if (data?.newDestination?.boundary?.coordinates) {
          coordinates.value = JSON.parse(
            data?.newDestination?.boundary?.coordinates
          );
        } else {
          coordinates.value = null;
        }
      }
    });

    watch(title, (title) => {
      refetch({ title });
    });

    const isEditing = ref(false);

    const boundary = ref(null);

    const { mutate: updateBoundingBox, loading: updateBoundingBoxLoading } =
      useMutation(UPDATE_DESTINATION, () => ({
        variables: {
          id: result?.value?.newDestination?.id,
          bounding_box: [
            props.bounds._southWest.lat,
            props.bounds._northEast.lat,
            props.bounds._southWest.lng,
            props.bounds._northEast.lng,
          ],
        },
      }));

    const {
      mutate: handleToggleBoundary,
      loading: handleToggleBoundaryLoading,
    } = useMutation(UPDATE_DESTINATION, () => ({
      variables: {
        id: result.value.newDestination.id,
        is_disabled: !result.value.newDestination.is_disabled,
      },
    }));

    const { mutate: updateBoundary, loading: updateBoundaryLoading } =
      useMutation(UPDATE_DESTINATION, () => ({
        variables: {
          id: result.value.newDestination.id,
          boundary: JSON.stringify(coordinates.value),
        },
      }));

    watchEffect(() => {
      emit(
        "update:is_loading",
        updateBoundingBoxLoading ||
          handleToggleBoundaryLoading ||
          updateBoundaryLoading
      );
    });

    const handleEdit = () => {
      if (!isEditing.value) {
        isEditing.value = true;

        props.map.leafletObject.pm.addControls({
          position: "topright",
          drawCircle: false,
          drawPolyline: false,
          drawMarker: false,
          drawRectangle: false,
          drawCircleMarker: false,
          rotateMode: false,
        });
        props.map.leafletObject.pm.enableGlobalEditMode({
          limitMarkersToCount: 20,
        });
      } else {
        isEditing.value = false;
        props.map.leafletObject.pm.removeControls();
        props.map.leafletObject.pm.disableGlobalEditMode();
      }
    };

    const handleScaleUp = () => {
      coordinates.value = buffer(boundary.value.geojson, 5, {
        units: "kilometers",
      });
    };

    const handleSimplify = () => {
      coordinates.value = simplify(boundary.value.geojson, {
        tolerance: 0.02,
        highQuality: true,
      });
    };

    const handleSaveBoundary = () => {
      let newCoordinates;
      props.map.leafletObject.pm.getGeomanLayers().forEach((layer, index) => {
        if (index === 0) {
          newCoordinates = layer.toGeoJSON(6);
        } else {
          coordinates.value = precision.parse(
            union(newCoordinates, layer.toGeoJSON(6)).geometry,
            6
          );
        }
      });

      coordinates.value = cleanCoords(newCoordinates);

      updateBoundary();
    };

    const handleAddBoundary = () => {
      handleEdit();
    };

    return {
      handleAddBoundary,
      coordinates,
      handleSaveBoundary,
      handleSimplify,
      handleScaleUp,
      boundary,
      result,
      route,
      isEditing,
      handleEdit,
      updateBoundingBox,
      loading,
      handleToggleBoundary,
    };
  },
};
</script>
