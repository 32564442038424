<template>
  <div
    class="
      bg-opacity-5 bg-black
      items-center
      justify-center
      absolute
      left-0
      right-0
      top-0
      bottom-0
      flex
    "
  >
    <div
      class="
        loader
        ease-linear
        rounded-full
        border-4 border-t-4 border-gray-200
        h-12
        w-12
        mb-4
      "
    ></div>
  </div>
</template>
