import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: { name: "villas" },
  },
  {
    path: "/destinations",
    name: "destinations",
    component: () =>
      import(
        /* webpackChunkName: "destinations" */ "../views/Destinations.vue"
      ),
    children: [
      {
        path: "blacklist",
        name: "destination-blacklists",
        component: () =>
          import(
            /* webpackChunkName: "destination" */ "../views/DestinationsBlacklist.vue"
          ),
      },
      {
        path: "",
        name: "destinations-result",
        component: () =>
          import(
            /* webpackChunkName: "destination" */ "../components/destinations/DestinationsResult.vue"
          ),
      },
    ],
  },
  {
    path: "/villas",
    name: "villas",
    component: () =>
      import(/* webpackChunkName: "villas" */ "../views/Villas.vue"),
  },
  {
    path: "/villas/:id/logs",
    name: "villaLogs",
    component: () =>
      import(/* webpackChunkName: "villaLogs" */ "../views/VillaLogs.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
