import { gql } from "graphql-tag";

export const GET_VENDORS = gql`
  query vendors($page: Int!, $perPage: Int = 15) {
    vendors(
      first: $perPage
      page: $page
      orderBy: { column: NAME, order: ASC }
      where: { HAS: { relation: "facilities" } }
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        name
      }
    }
  }
`;
