import { createApp, h, provide } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import apolloClient from "./apollo";
import "tailwindcss/tailwind.css";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { createApolloProvider } from "@vue/apollo-option";
import PortalVue from "portal-vue";

const VueResizeObserver = require("vue-resize-observer");

const apolloProvider = new createApolloProvider({
  defaultClient: apolloClient,
});

createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },
  render: () => h(App),
})
  .use(store)
  .use(router)
  .use(apolloProvider)
  .use(VueResizeObserver)
  .use(PortalVue)
  .mount("#app");
