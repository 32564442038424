import { gql } from "graphql-tag";

export const GET_VILLA = gql`
  query villa($id: String!) {
    villa(id: $id, by: id) {
      id
      title
      code
      is_quicklisting
      calendar {
        __typename
        ... on ICalCalendar {
          id
          source
          failed_at
          scanned_at
        }
        ... on LuxuryCalendar {
          id
        }
      }
    }
  }
`;

export const GET_VILLAS = gql`
  query villas(
    $page: Int = 1
    $where: QueryVillasWhereWhereConditions
    $hasCalendar: QueryVillasHasCalendarWhereHasConditions
    $hasVendor: QueryVillasHasVendorWhereHasConditions
    $hasCountry: QueryVillasHasCountryWhereHasConditions
    $perPage: Int = 20
  ) {
    villas(
      first: $perPage
      page: $page
      where: $where
      hasVendor: $hasVendor
      hasCountry: $hasCountry
      hasCalendar: $hasCalendar
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        title
        code
        is_quicklisting
        images
        status
        calendar_type
        calendar {
          __typename
          ... on ICalCalendar {
            id
            source
            failed_at
            scanned_at
          }
          ... on LuxuryCalendar {
            id
          }
        }
        country {
          name
        }
        vendor {
          name
        }
        reservations {
          start
          end
        }
        blackouts {
          start
          end
        }
      }
    }
  }
`;
