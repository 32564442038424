<template>
  <div class="flex md:flex-row flex-col px-2 sm:px-0 md:px-0">
    <div class="flex-grow py-2 md:py-0">
      <div class="relative flex items-center">
        <SearchIcon class="h-5 w-5 absolute ml-2 text-gray-500" />
        <input
          v-model="localSearch"
          type="text"
          name="search"
          id="search"
          class="
            rounded-md
            pl-10
            shadow-none
            focus:ring-gray-400 focus:border-gray-400
            block
            w-full
            text-md
            border-none
            bg-transparent
          "
          placeholder="Name, code..."
        />
      </div>
    </div>
    <Popover v-slot="{ open }" class="relative md:pl-4">
      <PopoverButton class="w-full">
        <button
          type="button"
          class="
            w-full
            justify-between
            inline-flex
            items-center
            px-4
            py-2
            shadow-sm
            text-md
            font-medium
            rounded-md
            text-white
            bg-gray-800
            hover:bg-gray-900
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-gray-700
          "
        >
          Filters
          <ChevronDownIcon
            :class="open ? 'transform -rotate-90' : ''"
            class="h-4 w-4 ml-3 -mr-1"
          />
        </button>
      </PopoverButton>
      <transition
        enter-active-class="transition ease-out duration-200"
        enter-from-class="opacity-0 translate-y-1"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-in duration-150"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 translate-y-1"
      >
        <PopoverPanel
          class="
            shadow-md
            w-80
            top-12
            right-0
            absolute
            z-10
            bg-white
            rounded-xl
            px-5
            pt-4
            pb-6
          "
        >
          <div class="flex justify-end">
            <span
              class="text-sm text-gray-500 font-medium"
              @click="clearFilters"
              >Clear</span
            >
          </div>

          <status-selector
            v-model="localStatus"
            class="col-span-2 mb-4"
          ></status-selector>

          <vendor-selector
            v-model="localVendor"
            class="col-span-2 mb-4"
          ></vendor-selector>
          <country-selector
            v-model="localCountry"
            class="col-span-2 my-4"
          ></country-selector>

          <div class="border-t border-gray-100 pt-4 mt-4">
            <fieldset class="space-y-3">
              <legend class="text-sm font-medium text-gray-900">
                Calendar Type
              </legend>
              <div class="relative flex items-start">
                <div class="flex items-center h-5">
                  <input
                    v-model="localCalendarType"
                    value="no-calendar"
                    id="nocalendar"
                    aria-describedby="nocalendar-description"
                    type="checkbox"
                    class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                    "
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label for="nocalendar" class="font-medium text-gray-700"
                    >No Calendar</label
                  >
                  <p id="nocalendar-description" class="text-gray-500">
                    Villas with no calendar.
                  </p>
                </div>
              </div>
              <div class="relative flex items-start">
                <div class="flex items-center h-5">
                  <input
                    v-model="localCalendarType"
                    value="api"
                    id="api"
                    aria-describedby="api-description"
                    type="checkbox"
                    class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                    "
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label for="api" class="font-medium text-gray-700">API</label>
                  <p id="api-description" class="text-gray-500">
                    Villas connected to luxuryrentals api.
                  </p>
                </div>
              </div>
              <div class="relative flex items-start">
                <div class="flex items-center h-5">
                  <input
                    v-model="localCalendarType"
                    value="i-calendar"
                    id="icalendar"
                    aria-describedby="icalendar-description"
                    type="checkbox"
                    class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                    "
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label for="icalendar" class="font-medium text-gray-700"
                    >iCalendar</label
                  >
                  <p id="icalendar-description" class="text-gray-500">
                    iCalendar connected villas.
                  </p>
                </div>
              </div>
            </fieldset>

            <fieldset class="space-y-3 mt-8">
              <legend class="text-sm font-medium text-gray-900">
                Calendar Status
              </legend>
              <div class="relative flex items-start">
                <div class="flex items-center h-5">
                  <input
                    v-model="localCalendarStatus"
                    value="succeeding"
                    id="succeeding"
                    aria-describedby="succeeding-description"
                    type="checkbox"
                    class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                    "
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label for="succeeding" class="font-medium text-gray-700"
                    >Succeeding</label
                  >
                  <p id="succeeding-description" class="text-gray-500">
                    Villas with good calendars.
                  </p>
                </div>
              </div>
              <div class="relative flex items-start">
                <div class="flex items-center h-5">
                  <input
                    v-model="localCalendarStatus"
                    value="failing"
                    id="failing"
                    aria-describedby="failing-description"
                    type="checkbox"
                    class="
                      focus:ring-indigo-500
                      h-4
                      w-4
                      text-indigo-600
                      border-gray-300
                      rounded
                    "
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label for="failing" class="font-medium text-gray-700"
                    >Failing</label
                  >
                  <p id="failing-description" class="text-gray-500">
                    Villas with failing calendars.
                  </p>
                </div>
              </div>
            </fieldset>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
</template>

<script>
import VendorSelector from "@/components/selectors/VendorSelector";
import StatusSelector from "@/components/selectors/StatusSelector";
import CountrySelector from "@/components/selectors/CountrySelector";
import { ChevronDownIcon, SearchIcon } from "@heroicons/vue/solid";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { computed } from "vue-demi";
import { useRouter } from "vue-router";

export default {
  components: {
    StatusSelector,
    VendorSelector,
    CountrySelector,
    ChevronDownIcon,
    Popover,
    PopoverButton,
    PopoverPanel,
    SearchIcon,
  },
  emits: [
    "update:status",
    "update:search",
    "update:vendor",
    "update:country",
    "update:calendar",
    "update:calendarStatus",
  ],
  props: {
    status: String,
    search: String,
    vendor: String,
    country: String,
    calendar: Array,
    calendarStatus: Array,
  },
  setup(props, { emit }) {
    const localSearch = computed({
      get: () => props.search,
      set: (value) => emit("update:search", value),
    });

    const localCountry = computed({
      get: () => props.country,
      set: (value) => emit("update:country", value),
    });

    const localVendor = computed({
      get: () => props.vendor,
      set: (value) => emit("update:vendor", value),
    });

    const localCalendarType = computed({
      get: () => props.calendar,
      set: (value) => emit("update:calendar", value),
    });

    const localCalendarStatus = computed({
      get: () => props.calendarStatus,
      set: (value) => emit("update:calendarStatus", value),
    });

    const localStatus = computed({
      get: () => props.status,
      set: (value) => emit("update:status", value),
    });

    const router = useRouter();

    const clearFilters = () => {
      router.push({ query: {} });
    };

    return {
      clearFilters,
      localStatus,
      localSearch,
      localCountry,
      localVendor,
      localCalendarType,
      localCalendarStatus,
    };
  },
};
</script>
