<template>
  <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
    <div v-if="result">
      <div
        class="mb-4"
        v-if="result.villa.calendar.__typename === 'ICalCalendar'"
      >
        <label for="calendar" class="block text-sm font-medium text-gray-700"
          >Calendar</label
        >
        <div class="mt-1">
          <input
            type="text"
            name="calendar"
            id="calendar"
            :value="result?.villa?.calendar?.source"
            class="
              shadow-sm
              focus:ring-indigo-500 focus:border-indigo-500
              block
              w-full
              sm:text-sm
              border-gray-300
              rounded-md
            "
            disabled
          />
        </div>
      </div>
      <calendar-logs :calendar-id="result?.villa?.calendar?.id"></calendar-logs>
      <iframe
        class="w-full overflow-hidden"
        style="height: 800px"
        :src="`https://d3bihkd3v75bj2.cloudfront.net/villas/${result?.villa?.code}/calendar`"
      >
      </iframe>
    </div>
  </div>
</template>

<script>
import { useQuery } from "@vue/apollo-composable";
import { useRoute } from "vue-router";
import { GET_VILLA } from "@/apollo/queries/villas";
import CalendarLogs from "@/components/villas/CalendarLogs";

export default {
  components: {
    CalendarLogs,
  },
  setup() {
    const route = useRoute();
    const { result } = useQuery(
      GET_VILLA,
      {
        id: route.params.id,
      },
      { fetchPolicy: "no-cache" }
    );

    return {
      result,
    };
  },
};
</script>
