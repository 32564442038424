<script>
import BaseSelector from "@/components/BaseSelector";
import { GET_COUNTRIES } from "@/apollo/queries/countries";

export default {
  extends: BaseSelector,
  props: {
    title: {
      default: "Country",
      type: String,
    },
    itemText: {
      default: "name",
      type: String,
    },
  },
  apollo: {
    countries: {
      query: GET_COUNTRIES,
      variables: {
        page: 1,
        perPage: 1000,
      },
    },
  },
  computed: {
    localOptions() {
      return this.countries && this.countries.data ? this.countries.data : [];
    },
  },
};
</script>
