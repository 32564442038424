import { gql } from "graphql-tag";

export const GET_COUNTRIES = gql`
  query countries($page: Int!, $perPage: Int = 15) {
    countries(
      first: $perPage
      page: $page
      orderBy: { column: NAME, order: ASC }
      where: { HAS: { relation: "villas" } }
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        name
      }
    }
  }
`;
