<template>
  <div>
    <Disclosure as="nav" class="bg-gray-800" v-slot="{ open }">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <img
                class="h-8 w-8"
                src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                alt="Workflow"
              />
            </div>
            <div class="xs:hidden md:block">
              <div class="ml-10 flex items-baseline space-x-4">
                <template v-for="(navItem, index) in navigation" :key="index">
                  <router-link
                    :to="{ name: navItem.name }"
                    v-slot="{ href, navigate, isActive }"
                    custom
                  >
                    <a
                      @click="navigate"
                      :href="href"
                      :class="[
                        isActive && 'bg-gray-900 text-white',
                        !isActive &&
                          'text-gray-300 hover:bg-gray-700 hover:text-white',
                      ]"
                      class="px-3 py-2 rounded-md text-sm font-medium"
                      v-text="navItem.label"
                    ></a>
                  </router-link>
                </template>
              </div>
            </div>
          </div>
          <div class="-mr-2 flex md:hidden">
            <!-- Mobile menu button -->
            <DisclosureButton
              class="
                bg-gray-800
                inline-flex
                items-center
                justify-center
                p-2
                rounded-md
                text-gray-400
                hover:text-white hover:bg-gray-700
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-offset-gray-800
                focus:ring-white
              "
            >
              <span class="sr-only">Open main menu</span>
              <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
        </div>
      </div>

      <DisclosurePanel class="md:hidden">
        <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <template v-for="(navItem, index) in navigation" :key="`m-${index}`">
            <router-link
              :to="{ name: navItem.name }"
              v-slot="{ href, navigate, isActive }"
              custom
            >
              <a
                @click="navigate"
                :href="href"
                :class="[
                  isActive && 'bg-gray-900 text-white',
                  !isActive &&
                    'text-gray-300 hover:bg-gray-700 hover:text-white',
                ]"
                class="block px-3 py-2 rounded-md text-base font-medium"
                v-text="navItem.label"
              ></a>
            </router-link>
          </template>
        </div>
      </DisclosurePanel>
    </Disclosure>

    <header class="bg-white shadow">
      <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <h1 v-show="false" class="text-xl font-semibold text-gray-900">
          Dashboard
        </h1>
      </div>
    </header>
    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
import { ref } from "vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { MenuIcon, XIcon } from "@heroicons/vue/outline";

const navigation = [
  {
    name: "villas",
    label: "Villas",
  },
  {
    name: "destinations-result",
    label: "Destinations",
  },
];
const profile = ["Your Profile", "Settings", "Sign out"];

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MenuIcon,
    XIcon,
  },
  setup() {
    const open = ref(false);

    return {
      navigation,
      profile,
      open,
    };
  },
};
</script>

<style>
body {
  background: #f3f4f6;
}
</style>
