<template>
  <div v-if="loading">Loading</div>
  <div v-if="result?.search" class="mt-2">
    <div
      v-for="destination in filterResults(result.search)"
      :key="destination.id"
    >
      <router-link
        :to="{
          name: 'destinations-result',
          query: { title: destination.title },
        }"
        v-slot="{ navigate, route: itemRoute }"
        custom
      >
        <a
          @click.self="navigate"
          :class="{
            'cursor-pointer': destination.children,
            'bg-gray-800 text-white':
              itemRoute.query?.title === route.query?.title,
            'bg-gray-200 hover:bg-gray-300 text-black': !(
              itemRoute.query?.title === route.query?.title
            ),
          }"
          class="
            px-4
            py-2
            rounded-md
            w-full
            text-left
            flex flex-row
            items-center
            justify-between
            text-sm
            cursor-pointer
          "
        >
          <span>{{ destination.title }}</span>
          <XIcon
            @click="handleBlacklistClicked(destination.title)"
            class="h-5 w-5"
          />
        </a>
      </router-link>
    </div>
  </div>
</template>

<script>
import { useMutation, useQuery } from "@vue/apollo-composable";
import { SEARCH_INDEX } from "@/apollo/queries/destinations";
import { FLAT_MENU_ITEMS } from "@/assets/data/destinations";
import { useRoute } from "vue-router";
import { XIcon } from "@heroicons/vue/solid";
import { CREATE_BLACKLIST } from "@/apollo/queries/search-blacklists";

export default {
  components: {
    XIcon,
  },
  props: {
    search: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const { result, loading } = useQuery(SEARCH_INDEX, () => ({
      filter: props.search,
    }));

    const filterResults = (destinations) => {
      return destinations.filter((destination) => {
        return (
          destination.type === "destinations" &&
          !FLAT_MENU_ITEMS.some((item) => {
            return item.title === destination.title;
          })
        );
      });
    };

    const route = useRoute();

    const { mutate: createBlacklist } = useMutation(CREATE_BLACKLIST);

    const handleBlacklistClicked = (title) => {
      createBlacklist({ title });
    };

    return { result, filterResults, loading, route, handleBlacklistClicked };
  },
};
</script>
