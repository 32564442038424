import { gql } from "graphql-tag";

export const SEARCH_INDEX = gql`
  query search($filter: String!) {
    search(filter: $filter) {
      id
      title
      type
    }
  }
`;

export const GET_DESTINATIONS = gql`
  query destinations($page: Int = 1, $perPage: Int = 15, $search: Mixed) {
    destinations(
      first: $perPage
      page: $page
      where: { column: TITLE, value: $search, operator: LIKE }
    ) {
      data {
        id
        title
        bounding_box
        location {
          lat
          lng
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

export const GET_DESTINATION = gql`
  query newDestination($title: String!) {
    newDestination(title: $title, include_disabled: true) {
      id
      type
      bounding_box
      is_disabled
      location {
        lat
        lng
      }
      boundary {
        __typename
        ... on DestinationPolygon {
          coordinates
        }
        ... on DestinationMultiPolygon {
          coordinates
        }
      }
    }
  }
`;
