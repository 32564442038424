<template>
  <div>
    <label
      v-if="showLabel"
      v-text="title"
      class="block text-sm font-medium text-gray-700"
    ></label>
    <select
      :multiple="multiple"
      v-model="localValue"
      class="
        mt-1
        block
        w-full
        pl-3
        py-2
        text-base
        border-gray-300
        focus:outline-none focus:ring-indigo-500 focus:border-indigo-500
        sm:text-sm
        rounded-md
      "
    >
      <option v-if="!multiple" :value="null"></option>
      <option
        v-for="(option, index) in filteredOptions"
        :key="index"
        :value="isSimple ? option : option[itemValue]"
        :selected="isSelected(option)"
      >
        {{ optionText(option) }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  emits: ["update:modelValue"],
  props: {
    isSimple: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: [Array, String, Number, Object, null],
      required: false,
      default: null,
    },
    title: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    itemValue: {
      validator: (prop) => typeof prop === "string" || prop === null,
      default: "id",
    },
    itemText: {
      validator: (prop) => typeof prop === "string" || prop === null,
      default: "name",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Function,
      default: () => true,
    },
    placeholder: {
      type: String,
      default: "Select option",
    },
    sort: {
      type: Function,
      default(data) {
        return data;
      },
    },
    options: {
      type: [Array, undefined],
      default: () => [],
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    searchKeys: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    optionText(option) {
      return this.isSimple ? option : option[this.itemText];
    },
    isSelected(option) {
      if (this.isSimple) {
        return this.localOptions === option;
      }

      return option[this.itemValue] === this.localValue;
    },
    displayText() {
      if (this.modelValue && this.isSimple) {
        return this.localValue;
      }

      if (this.modelValue && this.localValue) {
        return this.localValue[this.itemText];
      }

      return this.placeholder;
    },
  },
  computed: {
    isLoading() {
      return false;
    },
    localOptions() {
      return this.options;
    },
    filteredOptions() {
      return this.localOptions.filter(this.filter);
    },
    localValue: {
      get() {
        if (this.isSimple) return this.modelValue;
        else {
          if (this.multiple)
            if (this.returnObject) {
              return this.modelValue
                ? this.localOptions.filter((item) =>
                    this.modelValue.some(
                      (value) => value[this.itemValue] === item[this.itemValue]
                    )
                  )
                : [];
            } else {
              return this.localOptions.filter((option) =>
                this.modelValue.includes(option[this.itemValue])
              );
            }

          if (this.returnObject)
            return this.modelValue
              ? this.localOptions.find(
                  (item) =>
                    item[this.itemValue] === this.modelValue[this.itemValue]
                )
              : null;

          return this.localOptions.find(
            (item) => item[this.itemValue] === this.modelValue
          )?.[this.itemValue];
        }
      },
      set(val) {
        if (this.isSimple) {
          this.$emit("update:modelValue", val);
        } else if (this.multiple) {
          this.$emit(
            "update:modelValue",
            this.returnObject ? val : val.map((item) => item[this.itemValue])
          );
        } else if (val) {
          this.$emit("update:modelValue", val);
        } else if (val === null) {
          this.$emit("update:modelValue", null);
        }
      },
    },
  },
};
</script>
