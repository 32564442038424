import gql from "graphql-tag";

export const GET_LOGS = gql`
  query calendarLogs($calendar: Mixed!, $page: Int = 1) {
    activityLogs(
      first: 10
      page: $page
      where: {
        AND: [
          {
            column: SUBJECT_TYPE
            value: "FuquIo\\\\LaravelCalendar\\\\Monitoring\\\\VCalendar"
          }
          { column: SUBJECT_ID, value: $calendar }
          { column: CAUSER_TYPE, value: "App\\\\Models\\\\SystemAgent" }
          { column: CAUSER_ID, value: 2 }
        ]
      }
      orderBy: [{ column: CREATED_AT, order: DESC }]
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        description
        created_at
        properties
      }
    }
  }
`;
